<template>
  <div class="navbar-container d-flex content align-items-center">
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <feather-icon
        badge-classes="bg-danger"
        class="text-body mx-1"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-tooltip.hover.top="'Feedback!'"
        @click="showModalfeedback"
        icon="CoffeeIcon"
        id="my-modal"
        size="20"
      />
      <feather-icon
        badge-classes="bg-danger"
        class="text-body mx-1"
        @click="showModal"
         v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-tooltip.hover.top="'Any Changes!'"
        icon="KeyIcon"
        id="my-modal"
        size="20"
      />

      <locale />
      <!--<dark-Toggler class="d-none d-lg-block" />-->
      <!-- <search-bar /> -->
      <!-- <cart-dropdown /> -->
      <notification-dropdown />
      <user-dropdown />
    </b-navbar-nav>

    <b-modal
      v-model="addrequestfeedback"
      ref="my-modal"
      centered
      hide-footer
      title="Feedback !!!"
    >
      <b-alert variant="primary" show>
        <div class="alert-body">
          <span>
            We would love to know how you feel about our application
          </span>
        </div>
      </b-alert>
      <div class="d-block text-left">
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <label>Your Rating </label>
            <b-form-rating v-model="appData.star" no-border variant="danger">
              <feather-icon slot="icon-empty" icon="FrownIcon" size="18" />
              <feather-icon slot="icon-half" icon="MehIcon" size="18" />
              <feather-icon
                slot="icon-full"
                icon="SmileIcon"
                size="18"
                class="text-success"
              />
              <feather-icon slot="icon-clear" icon="XCircleIcon" size="18" />
            </b-form-rating>
            <inputtextarea
              name="details"
              class="mt-2"
              :tooltip="$t('fields.enter') + ' ' + $t('fields.details')"
              :label="$t('fields.details')"
              :placeholder="$t('fields.enter') + ' ' + $t('fields.details')"
              :valuex="appData.details"
              @updatedata="(val) => (appData.details = val)"
              validations="required"
            ></inputtextarea>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mt-3"
              variant="outline-secondary"
              block
              @click="onfeedbacksubSubmit"
            >
              Send
            </b-button>
          </b-form>
        </validation-observer>
      </div>
    </b-modal>
    <b-modal
      v-model="addrequest"
      centered
      hide-footer
      title="Want any changes ?"
    >
      <b-alert variant="primary" show>
        <div class="alert-body">
          <span>
            Are you having any troubles
          </span>
        </div>
      </b-alert>
      <div class="d-block text-left">
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <inputtextarea
              name="details"
              class="mt-2"
              :tooltip="$t('fields.enter') + ' ' + $t('fields.details')"
              :label="$t('fields.details')"
              :placeholder="$t('fields.enter') + ' ' + $t('fields.details')"
              :valuex="appData.details"
              @updatedata="(val) => (appData.details = val)"
              validations="required"
            ></inputtextarea>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mt-3"
              variant="outline-secondary"
              block
              @click="suggestion"
            >
              Send
            </b-button>
          </b-form>
        </validation-observer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BLink,
  BButton,
  BFormRating,
  VBTooltip,
  BForm,
  BFormGroup,
  BNavbarNav,
  BAvatar,
  BAlert,
} from "bootstrap-vue";
import Bookmarks from "./components/Bookmarks.vue";
import Locale from "./components/Locale.vue";
import SearchBar from "./components/SearchBar.vue";
import DarkToggler from "./components/DarkToggler.vue";
import CartDropdown from "./components/CartDropdown.vue";
import NotificationDropdown from "./components/NotificationDropdown.vue";
import UserDropdown from "./components/UserDropdown.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import store from "@/store";
import StoreModule from "./StoreModule";
import inputtext from "@/views/Component/Input/inputtext.vue";
import inputtextarea from "@/views/Component/Input/inputtextarea.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import formValidation from "@core/comp-functions/forms/form-validation";
export default {
  components: {
    BLink,
    BAlert,
    BAvatar,
    BButton,
    inputtextarea,
    inputtext,
    VBTooltip,
    BNavbarNav,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    CartDropdown,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    BFormRating,
    NotificationDropdown,
    BForm,
    BFormGroup,
    UserDropdown,
  },
  directives: {
     'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
    };
  },
  watch: {
    $route(to, from) {
      this.appData.routename = this.$route.name;
    },
  },

  setup(props, { emit }) {
    const GENAPP_APP_STORE_MODULE_NAME = "layouts-app";
    if (!store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
      store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);
    const toast = useToast();
    onUnmounted(() => {
      if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
        store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
    });

    const blankAppData = {
      details: "",
      star: 5,
      routename: "",
    };
    let addrequest = ref(false);
    let addrequestfeedback = ref(false);

    const appData = ref(JSON.parse(JSON.stringify(blankAppData)));

    var tempcatch = 1;
    var temp = null;
    const suggestion = () => {
      temp = appData.value;
      let formData = new FormData();
      let appdatax = {
        routename: temp.routename,
        details: temp.details,
      };
      var jx = {
        data: appdatax,
      };
      if (tempcatch) {
        tempcatch = 0;
        store
          .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/suggestion", jx)
          .then((response) => {
            if (response.data) {
              toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  text: " we will get back to you shortly",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              // document.getElementById("my-modal").click();
              appData.value.details = "";
              addrequest.value = false;
            } else if (response.data) {
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  variant: "error",
                },
              });
            }
            tempcatch = 1;
          })
          .then(() => {
            tempcatch = 1;
          })
          .catch((error) => {
            tempcatch = 1;
            if (error.response.status === 404) {
              appData.value = undefined;
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  text: "Please Contact Support Team",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          });
      }
    };

    const onfeedbacksubSubmit = () => {
      temp = appData.value;
      let formData = new FormData();
      let appdatax = {
        star: temp.star,
        details: temp.details,
      };
      var jx = {
        data: appdatax,
      };
      if (tempcatch) {
        tempcatch = 0;
        store
          .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/feedback", jx)
          .then((response) => {
            if (response.data) {
              toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  text: " we will get back to you shortly",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              // document.getElementById("my-modal").click();
              appData.value.details = "";
              addrequestfeedback.value = false;
            } else if (response.data) {
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  variant: "error",
                },
              });
            }
            tempcatch = 1;
          })
          .then(() => {
            tempcatch = 1;
          })
          .catch((error) => {
            tempcatch = 1;
            if (error.response.status === 404) {
              appData.value = undefined;
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  text: "Please Contact Support Team",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          });
      }
    };

    const resetappData = () => {
      appData.value = JSON.parse(JSON.stringify(blankAppData));
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetappData);
    return {
      GENAPP_APP_STORE_MODULE_NAME,
      appData,
      suggestion,
      getValidationState,
      tempcatch,
      temp,
      onfeedbacksubSubmit,
      addrequest,
      addrequestfeedback,
    };
  },
  mounted() {
  if(this.userData == null ){
      this.$router.push({ name: "auth-login" });
  }
    this.appData.routename = this.$route.name;
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    showModalfeedback() {
      this.addrequestfeedback = true;
    },
    showModal() {
      this.addrequest = true;
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
  },
};
</script>
